import classNames from "classnames"
import Link from "next/link"
import SbEditable from "storyblok-react"
import { BsArrowDown } from "react-icons/bs";

const CardDownload = ({blok}) => {
    return (
        <SbEditable content={blok}>
            <Link href={blok.link.linktype === 'asset' ? blok.link.cached_url : (blok.link.cached_url === 'startseite/' ? '/' : `/${blok.link.cached_url}`)}>
            <a className={classNames("p-8 text-white group",{
                'bg-blue-700': blok.bg_color === 'blue-700',
                'bg-blue-600': blok.bg_color === 'blue-600',
                'bg-blue-date': blok.bg_color === 'blue-date',
            })} target={blok.link.linktype === 'asset' ? '_blank' : '_self'}>
                <h3 className="font-semiBold text-2xl leading-none lg:text-3xl">{blok.title}</h3>
                <div className="flex gap-3 items-end justify-end mt-48 lg:text-lg leading-none">
                    <span>Herunterladen</span>
                    <BsArrowDown className="group-hover:animate-bounce text-2xl"/>
                </div>
            </a> 
            </Link>
        </SbEditable>
    )
}

export default CardDownload