import React from 'react';
import SbEditable from 'storyblok-react';

const Component = ({ blok }) => (
  <SbEditable content={blok}>
    <a className="inline-flex items-center px-5 py-2 text-lg text-white hover:bg-blue-200 bg-blue-700 font-regular lg:px-6 lg:py-3" href={blok?.button_link?.cached_url} target={blok?.button_link?.linktype === 'asset' ? '_blank' : '_self'} rel={blok?.button_link?.linktype === 'asset' ? 'noreferrer' : ''}>
      <span className="pr-8">{blok.button_label}</span>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 64 64" className="inline-block w-10 h-10 pl-4">
        <path className="fill-current" d="M62.9,29.1L55.3,20c-0.5-0.6-1.5-0.7-2.1-0.2c-0.6,0.5-0.7,1.5-0.2,2.1l7.2,8.6H1.5C0.7,30.5,0,31.2,0,32 c0,0.8,0.7,1.5,1.5,1.5h58.7L53,42.1c-0.5,0.6-0.5,1.6,0.2,2.1c0.3,0.2,0.6,0.4,1,0.4c0.4,0,0.9-0.2,1.1-0.5l7.7-9.1	C64.4,33.3,64.4,30.7,62.9,29.1z" />
      </svg>
    </a>
  </SbEditable>
);

export default Component;
