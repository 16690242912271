import React from 'react';
import SbEditable from 'storyblok-react';

const Image = ({ blok }) => (
  <SbEditable content={blok}>
    <a href={blok.link?.cached_url || '#'} className="flex flex-col justify-end px-6 py-5 transition transition-shadow duration-300 transform bg-center bg-cover lg:col-span-2 hover:shadow-m1 shadow-in" style={{ backgroundImage: `url(${blok.image?.filename})`, height: '300px' }} target={blok.link?.linktype === 'asset' ? '_blank' : '_self'} rel={blok.link?.linktype === 'asset' ? 'noreferrer' : ''}>
      <h3 className="flex-none w-full text-4xl leading-tight text-white uppercase font-semiBold">{blok.heading}</h3>
      <p className="flex-none w-full text-xl leading-none text-white">{blok.data}</p>
    </a>
  </SbEditable>
);

export default Image;
